import React, { Component } from 'react';
import Button from '../../../atoms/Button/Button';

import './VirtualTour.css';

export default class VirtualTour extends Component {

  render() {

    let imageUrl = null;
    const image = this.props.item.get('field_image');

    if(image) {
        imageUrl = image.get('field_media_image').get('links').hd1080.href;
    }

    return (
        <div className="paragraph virtual-tour">
            <div className="content" style={{ backgroundImage: `url(${imageUrl})` }}>

                <div className="details">
                    <div className="headline">Virtual Tour</div>
                    <div className="link">
                        <Button buttonClasses="dark ghost" target="_blank" url={this.props.item.get('field_link').uri} text="View Virtual Tour" />
                    </div>
                </div>

            </div>
        </div>
    );
  }
}