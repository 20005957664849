export default class SiteInfo {
    static UUIDS = {
        OUTLET: '2a65376d-b0e9-4e57-ad8f-a1073aba9971',
        NEWS: '2bc366dd-f66c-4cc7-95bd-4ead3ed81239',
        MARKETS: '518edf1c-5376-4828-865c-4f2b6fe00248',
        CUSTOMERS: 'd4cb63b3-0fde-41f1-bd77-704a96e00c5f',
        FRONT: 'bb4d8d4d-5ec5-43c4-bcbe-26687f7ba21f'
    };

    static getOutlet = () => {
        return SiteInfo.UUIDS.OUTLET;
    };

}
