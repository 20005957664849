import React, { Component } from 'react';
import PropertyListWidget from './PropertyListWidget/PropertyListWidget';
import PropertyGridWidget from './PropertyGridWidget/PropertyGridWidget';
import PropertyMapWidget from './PropertyMapWidget/PropertyMapWidget';
import PropertyWidgetPicker from './PropertyWidgetPicker/PropertyWidgetPicker';

import './PropertyPicker.css';

export default class PropertyPicker extends Component {
    
    state = {
        currentWidget: 'grid'
    };

    render() {
        let content = this.getSelectedWidget();

        return (
            <div className="property-picker page-section padded">
                <PropertyWidgetPicker key={this.props.marketId} activeWidget={this.state.currentWidget} onSelect={this.selectWidget} />

                {content}
            </div>
        );

    }

    selectWidget = (e) => {
        this.setState({
            currentWidget: e.props.value
        });
    }

    getSelectedWidget = () => {
        let widget = null;

        if(this.props.properties.hasOwnProperty('data')) {

            switch(this.state.currentWidget) {
                case 'list':
                    widget = <PropertyListWidget key={this.props.marketId} properties={this.props.properties} />;
                    break;
                case 'grid':
                    widget = <PropertyGridWidget properties={this.props.properties} />;
                    break;
                case 'map':
                    widget = <PropertyMapWidget properties={this.props.properties} outlet={this.props.outlet} />;
                    break;
                default:
                    widget = <PropertyListWidget key={this.props.marketId} properties={this.props.properties} />;
            }
        }

        return (
            <div className="property-picker">
                {widget}

                {this.props.virtualTour}
            </div>
        );
    }

}